<template>
  <div>
    <b-modal
      id="change-state"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ShipToManufactory')"
      hide-footer
      size="lg"
      @close="onCancel"
    >
      <b-form>
        <div style="display:flex;gap: 15px;padding-bottom: 5px;">
          <b-form-group
            style="width: 100%;"
            :label="$t('SelectD')"
          >
            <date-picker
              v-model="movementDate"
              value-type="format"
              :placeholder="$t('SelectD')"
              :format="'YYYY-MM-DD'"
              :clearable="false"
              :lang="lang"
              style="width: 100%;"
            />
          </b-form-group>
          <b-form-group
            style="width: 100%;"
            :label="$t('SenderLocation')"
          >
            <vue-select
              v-model="fromLocation"
              :options="getLocationsMoving"
              label="locationName"
              :reduce="(e) => e.locationId"
              :placeholder="$t('From')"
            />
          </b-form-group>
        </div>
        <div style="display: flex; gap:15px;padding-bottom: 5px;">
          <b-form-group
            style="width: 100%;"
            :label="$t('ReceiverLocation')"
          >
            <vue-select
              v-model="toLocation"
              :options="getLocationsMoving"
              label="locationName"
              :reduce="(e) => e.locationId"
              :placeholder="$t('To')"
              style="width: 100%;"
            />
          </b-form-group>
          <b-form-group
            style="width: 100%;"
            :label="$t('AssignTeam')"
          >
            <vue-select
              v-model="teams"
              :options="getTeamsNames"
              label="teamName"
              :reduce="(e) => e.teamNameId"
              :placeholder="$t('AssignTeam')"
              style="width: 100%;"
            />
          </b-form-group>
        </div>
        <b-form-group
          :label="$t('Orders')"
        >
          <vue-select
            v-model="orderItemIdss"
            :options="orderItemIds"
            :reduce="(e) => e.orderItemId"
            label="orderNumber"
            style="width: 100%;"
            :multiple="true"
            :placeholder="$t('Select')"
          />
        </b-form-group>
      </b-form>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          style="margin-left: 0px; background: #FF274F !important;"
          class="buttonSubmit"
          :disabled="isButtonDisabled"
          @click="onSubmit"
        >
          {{ $t('Confirm') }}
        </b-button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: ['orderItemIds'],
  data() {
    return {
      teams: null,
      fromLocation: null,
      toLocation: null,
      movementDate: null,
      orderItemIdss: [],
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
      },
    }
  },
  computed: {
    ...mapGetters(['getLocationsMoving', 'getTeamsNames']),
    isButtonDisabled() {
      return (
        !this.teams
        || !this.fromLocation
        || !this.toLocation
        || !this.movementDate
        || this.orderItemIdss.length === 0
      );
    },
  },
  watch: {
    orderItemIds(value) {
      if (value) {
        this.orderItemIdss = value
      }
    },
  },
  methods: {
    onSubmit() {
      const orders = this.orderItemIdss.map((item) => { return item.orderItemId ? item.orderItemId : item })
      const objekti = {
        deliverDate: this.movementDate,
        deliverLocationId: this.fromLocation,
        receiverLocationId: this.toLocation,
        teamNameId: this.teams,
        orderItemIds: orders,
      }
      console.log('onSubmit', objekti)
      this.$emit('onSubmit', objekti)
      this.$refs.modal.hide()
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.fromLocation = null
      this.teams = null
      this.toLocation = null
      this.movementDate = null
      if (this.orderItemIdss != this.orderItemIds) {
        this.orderItemIdss = this.orderItemIds
      }
    },
  },
}
</script>

<style>
.mx-input {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    padding: 0px 34px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 1.4;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}
</style>
